<template>
  <span class="d-flex">
    <v-chip
      v-if="invoice.paid"
      small
      outlined
      color="green"
    >
      {{$t('t.Paid') + ' ' + paidText }}
    </v-chip>
    <v-chip
      v-if="isDue"
      color="red"
      small
      outlined
    >
      {{$t('t.Overdue') + ' ' + days | capitalize }}
    </v-chip>
    <span v-if="!isDue && !invoice.paid">
      {{$t('t.Due') + ' ' + days | capitalize }}
    </span>
  </span>
</template>

<script>
export default {
  computed: {
    isDue () {
      return !this.invoice.paid && this.moment(this.invoice.due) < this.moment().startOf('day')
    },
    days () {
      const today = this.moment().startOf('day')
      return today.diff(this.moment(this.invoice.due)) ? this.moment(this.invoice.due).from(today) : today.calendar(null, { sameDay: `[${this.$t('t.Today')}]` })
    },
    paidText () {
      if (!this.invoice.paid) {
        return ''
      }
      const paid = this.moment(this.invoice.paid)
      const now = this.moment()

      if (now.diff(paid, 'days') >= 15) {
        return paid.calendar(null, { sameDay: `[${this.$t('t.Today')}]` })
      }

      return paid.from(this.moment().startOf('day'))
    }
  },
  props: {
    invoice: Object
  }
}
</script>
